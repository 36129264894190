exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("assets/fonts/krungsri-condensed/krungsri_con_bol-webfont.woff"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("assets/fonts/krungsri-condensed/krungsri_con_med-webfont.woff"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("assets/fonts/krungsri-condensed/krungsri_con-webfont.woff"));

// Module
exports.push([module.id, "@font-face{font-family:Krungsri;src:url(" + ___CSS_LOADER_URL___0___ + ") format(\"woff\");font-weight:700;font-display:swap}@font-face{font-family:Krungsri;src:url(" + ___CSS_LOADER_URL___1___ + ") format(\"woff\");font-weight:500;font-display:swap}@font-face{font-family:Krungsri;src:url(" + ___CSS_LOADER_URL___2___ + ") format(\"woff\");font-weight:400;font-display:swap}", ""]);

