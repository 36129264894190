exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("assets/fonts/krungsri-condensed/krungsri_con_bol-webfont.woff"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("assets/fonts/krungsri-condensed/krungsri_con_med-webfont.woff"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("assets/fonts/krungsri-condensed/krungsri_con-webfont.woff"));

// Module
exports.push([module.id, "@font-face{font-family:Krungsri;src:url(" + ___CSS_LOADER_URL___0___ + ") format(\"woff\");font-weight:700;font-display:swap}@font-face{font-family:Krungsri;src:url(" + ___CSS_LOADER_URL___1___ + ") format(\"woff\");font-weight:500;font-display:swap}@font-face{font-family:Krungsri;src:url(" + ___CSS_LOADER_URL___2___ + ") format(\"woff\");font-weight:400;font-display:swap}html{line-height:1.5}@media screen and (max-width:767px){html{font-size:87.5%}}@media print,screen and (min-width:768px){html{font-size:100%}}h1,h2,h3,h4,h5,h6,p{margin:0}*{font-family:Krungsri,sans-serif}@media screen and (max-width:767px){*{font-size:14px}}@media print,screen and (min-width:768px){*{font-size:16px}}body,button,input,select,textarea{font-family:Krungsri,sans-serif}a{color:inherit;text-decoration:none}body{color:#444}.form-input-box{width:100%;height:100%}.form-input-box input,.form-input-box select{width:100%;height:48px}.tabs ul{border-bottom-width:0}.grecaptcha-badge{visibility:hidden}", ""]);

